@keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .slide-fade {
    animation: fade 0.5s ease-in-out;
  }

  .header__slider_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 25px 0;
    border-bottom: 1px solid #A3A3A3;
  }
  
  .slider__container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto; 
    margin-right: auto; 
    padding-right: 5%;
  }
  
  .slider_goBackButton_container {
    margin-left: 30px; /* Отступ слева */
  }

  .slider__button-left {
    margin-right: 66px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16" fill="none"><path d="M9.5 15L1 8L9.5 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        background-repeat: no-repeat;
        background-position: center center;
    }
  

  .slider__button-right {
    margin-left: 66px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16" fill="none"><path d="M1.5 15L10 8L1.5 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-repeat: no-repeat;
    background-position: center center;
  }

  .slider__button-left,
  .slider__button-right {
      border: none;
      cursor: pointer;
      background-color: #FAFAFA;
      width: 10px; /* Установите желаемую ширину */
      height: 15px; /* Установите желаемую высоту */
  }

  
