

.notFoundPage__container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
}

.notFound__text {
    text-align: center;
    font-size: 40px;
}

.notFound__subtext {
    text-align: center;
    font-size: 20px;
}

.notFoundButton {
    font-size: 30px;
    width: 100%;
}