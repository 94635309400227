@import url(../Puzzle_System_Coagulation/Puzzle_components.css);

/*Размеры и позиционирование*/
.puzzle__workspace_panel .back_con_el_1,
.puzzle__workspace_panel .back_con_el_6,
.puzzle__workspace_panel .back_con_el_7,
.puzzle__workspace_panel .back_con_el_8 {
  position: absolute;
  width: 106px;
  height: 31px;
}

.puzzle__workspace_panel .back_con_el_2,
.puzzle__workspace_panel .back_con_el_3,
.puzzle__workspace_panel .back_con_el_4,
.puzzle__workspace_panel .back_con_el_5,
.puzzle__workspace_panel .back_con_el_10 {
  position: absolute;
  width: 41px;
  height: 31px;
}

.puzzle__workspace_panel .back_con_el_9 {
  position: absolute;
  width: 131px;
  height: 31px;
}

/*Расположение*/
.puzzle__workspace_panel .back_con_el_1 {
  top: 20px;
  left: 35px;
}

.puzzle__workspace_panel .back_con_el_2 {
  top: 80px;
  left: 19px;
}
.puzzle__workspace_panel .back_con_el_3 {
  top: 140px;
  left: 70px;
}

.puzzle__workspace_panel .back_con_el_4 {
  top: 200px;
  left: 120px;
}
.puzzle__workspace_panel .back_con_el_5 {
  top: 260px;
  left: 165px;
}

.puzzle__workspace_panel .back_con_el_6 {
  top: 320px;
  left: 450px;
}
.puzzle__workspace_panel .back_con_el_7 {
  top: 379px;
  left: 366px;
}

.puzzle__workspace_panel .back_con_el_8 {
  top: 379px;
  left: 532px;
}
.puzzle__workspace_panel .back_con_el_9 {
  top: 320px;
  left: 635px;
}

.puzzle__workspace_panel .back_con_el_10 {
  top: 200px;
  left: 452px;
}

