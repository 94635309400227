#root {
  background-color: #fff;
  font-family: "Inter", Arial, sans-serif;
  margin: 0;
}

@import url("./vendor/normalize.css");
@import url("./vendor/fonts/inter.css");


