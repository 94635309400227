.image-button-container {
  position: relative;
  display: inline-block;
}
  
.button-image {
  cursor: pointer;
  transition: transform 0.2s;
}

.button-image:active {
  transform: translateY(4px);
}

@keyframes myAnim {
	0% {
		transform: scale(0.5);
		transform-origin: 50% 100%;
	}

	100% {
		transform: scale(1);
		transform-origin: 50% 100%;
	}
}
  
.popup-svg {
  animation: myAnim 1s ease 0s 1 normal forwards;
  position: absolute;
  top:-35px; 
  left: -10px; 
  transform: translateX(-50%);
  z-index: 2;
  cursor: pointer;
  width: 100px;
  height: 50px;
}

.popup-svg:hover {
  fill: #6D349A;
}

