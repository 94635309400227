.footer {
    background-color: #1D1D20;
    color: #fff;
    min-height: 180px;
    margin-top: auto;
  }
  
  .footer-content {
    display: flex;
  }
  
  .footer-section {
    display: flex;
    flex-direction: row;
    gap: 100px;
    margin: 3% 0 50px 22.3%;
  }
  
  .footer-section-text {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    margin-top: 0;
  }

  .text_socials {
    opacity: 0.3;
  }
  
  .footer-section_part {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footer_logo {
    margin: 68px 0 67px 73%;
  }

  .footer-section_part-half{
    display: flex;
    flex-direction: column;
    gap: 0;
  }

