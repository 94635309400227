.bar {
    text-align: center;
  }
  
  .status-bar {
    width: 842px;
    height: 9px;
    background-color: #c0c0c0;
    position: relative;
    margin-bottom: 20px;
  }
  
  .progress {
    position: absolute;
    height: 100%;
    background-color: #8f00ff;
    transition: width 0.3s ease-in-out;
  }
  
  .bar button {
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .video__button_left-all {
    background-color: #f8f8f8;
    width: 35px;
    height: 21px;
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    cursor: pointer;
    background-image: url("../../../Media//Anticoagulants/Video/VideoSystemCoagulation/VideoBtnLeftAll.svg");
  }
  .video__button_left {
    background-color: #f8f8f8;
    width: 21px;
    height: 21px;
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    cursor: pointer;
    background-image: url("../../../Media/Anticoagulants/Video/VideoSystemCoagulation/VideoBtnLeft.svg");
  }
  
  .video__button_right {
    background-color: #f8f8f8;
    width: 21px;
    height: 21px;
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    cursor: pointer;
    background-image: url("../../../Media/Anticoagulants/Video/VideoSystemCoagulation/VideoBtnRight.svg");
  }
  
  .video__button_right-all {
    background-color: #f8f8f8;
    width: 35px;
    height: 21px;
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    opacity: 50%;
    background-image: url("../../../Media/Anticoagulants/Video/VideoSystemCoagulation/VideoBtnRightAll.svg");
  }
  