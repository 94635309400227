.main-content {
  display: flex;
  flex-direction: row;
  justify-self: center;
  margin: 18px auto 0 auto;
  padding-right: 100px;
  justify-content: space-between;
  width: 100%;
  max-width: 1131px;
}

.main_text {
  font-size: 48px; 
  font-weight: 900;
  font-family: 'Inter';
  line-height: 1.1;
  margin: 10px 0 0 0;
  letter-spacing: -3px;
}

.main_subtext {
  font-size: 24px; 
  margin-top: 25px;
  margin-bottom: 54px;
}

.trial-button {
  background-color: #8F00FF;
  transition: 0.3s ease;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px; 
  font-family: 'Helvetica Bold', sans-serif; 
  width: 503px;
  height: 59px;
}

.feedback-button {
  background-color: #9000ff00;
  transition: 0.3s ease;
  color: #000000;
  border: 1px solid black;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px; 
  font-family: 'Helvetica Bold', sans-serif; 
  width: 503px;
  height: 59px;
  margin-top: 15px;
}

.feedback {
  position: relative;
}

.feedback:hover .feedback-button {
  background-color: black;
  color: white;
}
.feedback-ref {
  position: absolute;
  width: 100%;
  height: 100%;
}

.trial-button:hover {
  background-color: #8049AD;
}

.main-content_media__img {
  width: 340x;
  height: 340px;
}

.main-content_text-container {
  max-width: 503px;
}

.goBackButton__container {
  position: fixed;
}