.header {
    background-color: #F8F8F8;
    min-height: 71px;
    border-bottom: 1px solid rgba(35, 35, 35, .4);
  }

  .header_logo {
    margin: 29px 0 29px 0;
    margin-left: 16.8%;
  }
  